import { FC } from 'react';
import Fade from 'react-reveal/Fade';
import { texts } from 'src/constants';
import { Grid, Typography } from '@mui/material';
import { useStyles } from './ExpectationsStyles';

const frontierHomes = [
  {
    id: 1,
    point: '01',
    headings: texts.expectations.promptAllocation,
    description: texts.expectations.weAreKnown,
  },
  {
    id: 2,
    point: '02',
    headings: texts.expectations.integrity,
    description: texts.expectations.wiseManOnceSaid,
  },
  {
    id: 3,
    point: '03',
    headings: texts.expectations.qualityService,
    description: texts.expectations.weCareAboutOurClients,
  },
  {
    id: 4,
    point: '04',
    headings: texts.expectations.customerSatisfaction,
    description: texts.expectations.ultimateGoal,
  },
];

const Expectations: FC = () => {
  const classes = useStyles();

  return (
    <section className={classes.section}>
      <Fade left>
        <Typography className={classes.whyUs} variant='h3'>
          {texts.expectations.perfectChoice}
        </Typography>
        <Grid container spacing={3} className={classes.gridLine}>
          {frontierHomes.map((frontierHome) => (
            <Grid item xs={12} sm={6} md={6} key={frontierHome.id}>
              <Typography className={classes.number} variant='body2'>
                {frontierHome.point}.
              </Typography>
              <Typography className={classes.title} variant='body2'>
                {frontierHome.headings}
              </Typography>
              <Typography className={classes.description} variant='body2'>
                {frontierHome.description}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Fade>
    </section>
  );
};

export default Expectations;
