import { FC } from 'react';
import Fade from 'react-reveal/Fade';
import ReactPlayer from 'react-player';
import { testimonialsOne, testimonialsTwo } from 'src/constants/links';
import { texts } from 'src/constants';
import { Typography, Box, Grid } from '@mui/material';
import { useStyles } from './TestimonialsStyles';

const TestimonialsVideos = ({ url }: { url: string }) => {
  return (
    <ReactPlayer
      url={url}
      controls
      width='100%'
      style={{ objectFit: 'cover' }}
    />
  );
};

const Testimonials: FC = () => {
  const classes = useStyles();

  return (
    <section className={classes.section}>
      <Box className={classes.textWrapper}>
        <Fade bottom cascade>
          <Typography className={classes.title} variant='h2'>
            {texts.general.testimonials}
          </Typography>
        </Fade>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6}>
          <TestimonialsVideos url={testimonialsOne} />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TestimonialsVideos url={testimonialsTwo} />
        </Grid>
      </Grid>
    </section>
  );
};

export default Testimonials;
