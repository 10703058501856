import { FC, useEffect, useState, useCallback } from 'react';
import { Loader } from 'src/components';
import ImgsViewer from 'react-images-viewer';
import * as service from 'src/service';
import Carousel from 'react-material-ui-carousel';
import CarouselImage from './CarouselImage';

const Banner: FC = () => {
  const [banner, setBanner] = useState<{ banner: { url: string } }[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [imageViewer, setImageViewer] = useState({
    show: false,
    currentImg: 0,
  });

  const fetchBanners = useCallback(() => {
    setIsLoading(true);
    service.home
      .getBanners()
      .then((res) => {
        setBanner(res.data.banner);
        setIsLoading(false);
      })
      .catch(console.error)
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchBanners();
  }, [fetchBanners]);

  const prevImage = () => {
    setImageViewer((prevState) => ({
      ...prevState,
      currentImg: Math.max(prevState.currentImg - 1, 0),
    }));
  };

  const nextImage = () => {
    setImageViewer((prevState) => ({
      ...prevState,
      currentImg: Math.min(prevState.currentImg + 1, banner.length - 1),
    }));
  };

  const closeImageViewer = () => {
    setImageViewer((prevState) => ({
      ...prevState,
      show: false,
    }));
  };

  if (isLoading) {
    return <Loader />;
  }

  if (banner.length === 0) {
    return null;
  }

  const imageList = banner.map((image) => ({
    src: image?.banner?.url || '',
  }));

  return (
    <>
      {banner.length > 1 ? (
        <Carousel>
          {banner.map((image: any, i: number) => {
            return (
              <CarouselImage
                key={i}
                image={image}
                i={i}
                setImageViewer={setImageViewer}
                banner={banner}
              />
            );
          })}
        </Carousel>
      ) : (
        <CarouselImage
          key={0}
          image={banner[0]}
          i={0}
          setImageViewer={setImageViewer}
          banner={banner}
        />
      )}
      {banner?.length > 1 && (
        <ImgsViewer
          imgs={imageList}
          backdropCloseable={true}
          currImg={imageViewer.currentImg}
          isOpen={imageViewer.show}
          onClickPrev={prevImage}
          onClickNext={nextImage}
          onClose={closeImageViewer}
        />
      )}
    </>
  );
};

export default Banner;
