import { FC, useState, useEffect, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { routing } from 'src/utils';
import { texts } from 'src/constants';
import * as service from 'src/service';
import * as links from 'src/constants/links';
import logo4 from 'src/assets/logo4.png';
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  SvgIconTypeMap,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { footerFeatures, footerTexts } from './footerData';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useStyles } from './FooterStyles';
import { OverridableComponent } from '@mui/material/OverridableComponent';

type Menu = {
  title: string;
  menu?: { name: string; path: string }[];
  address?: {
    icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & { muiName: string };
    text: string;
  }[];
};
const RedirectLink = ({
  to,
  children,
}: {
  to: string;
  children: ReactNode;
}) => (
  <a href={to} target='_blank' rel='noreferrer'>
    {children}
  </a>
);

const SocialMediaLinks = ({ className }: { className: string }) => {
  const socialMedia = [
    {
      name: 'Facebook',
      url: links.facebook,
      icon: <FacebookIcon sx={{ color: '#fff' }} />,
    },
    {
      name: 'Instagram',
      url: links.instagram,
      icon: <InstagramIcon sx={{ color: '#fff' }} />,
    },
    {
      name: 'Twitter',
      url: links.twitter,
      icon: <TwitterIcon sx={{ color: '#fff' }} />,
    },
  ];

  return (
    <Box className={className}>
      {socialMedia.map(({ name, url, icon }) => (
        <RedirectLink key={name} to={url}>
          {icon}
        </RedirectLink>
      ))}
    </Box>
  );
};

const RenderMenu: FC<{ menu: Menu; propertyName: Record<string, any>[] }> = ({
  menu,
  propertyName,
}) => {
  const classes = useStyles();

  switch (menu.title) {
    case footerFeatures.discover:
      return (
        <>
          {propertyName.slice(0, 6).map((name) => (
            <Link
              key={name._id}
              to={`${routing.propertyName}/${name._id}`}
              className={classes.link}
            >
              <ListItem>
                <KeyboardArrowRightIcon />
                <ListItemText primary={name._id} />
              </ListItem>
            </Link>
          ))}
        </>
      );

    case footerFeatures.quickLinks:
      return (
        <>
          {menu.menu?.map((item) => (
            <Link to={item.path} key={item.name} className={classes.link}>
              <ListItem>
                <ListItemText primary={item.name} />
              </ListItem>
            </Link>
          ))}
        </>
      );

    case footerFeatures.contactUs:
      return (
        <>
          {menu.address?.map((item) => (
            <ListItem sx={{ columnGap: '1rem' }} key={item.text}>
              <item.icon />
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </>
      );

    default:
      return null;
  }
};

const Footer: FC = () => {
  const classes = useStyles();
  const isNonMobile = useMediaQuery(useTheme().breakpoints.up('sm'));
  const [propertyName, setPropertyName] = useState<Record<string, any>[]>([]);

  useEffect(() => {
    service.property
      .getAllPropertyNames()
      .then((res) => {
        setPropertyName(res.data.properties);
      })
      .catch(console.error);
  }, []);

  const menus = Object.values(footerTexts);

  return (
    <footer className={classes.footer}>
      <Grid container spacing={2} className={classes.gridLine}>
        <Grid item xs={12} sm={6} md={3}>
          <img src={logo4} alt='logo' className={classes.footerImg} />
          {isNonMobile && <SocialMediaLinks className={classes.socialMedia} />}
        </Grid>
        {menus.map((menu) => (
          <Grid item xs={6} sm={6} md={3} key={menu.title}>
            <Box className={classes.discoverWrapper}>
              <Typography variant='h4'>{menu.title}</Typography>
              <List>
                <RenderMenu menu={menu} propertyName={propertyName} />
              </List>
            </Box>
          </Grid>
        ))}
        {!isNonMobile && (
          <Grid item xs={6}>
            <SocialMediaLinks className={classes.socialMedia} />
          </Grid>
        )}
      </Grid>
      <Box className={classes.newDate}>
        ©{new Date().getFullYear()} {texts.footer.frontierHomes}
      </Box>
    </footer>
  );
};

export default Footer;
