import { FC, useState } from 'react';
import cx from 'classnames';
import { Box, Grid, Typography, Card, CardContent } from '@mui/material';
import { useStyles } from './TeamsInformationStyles';

interface ITeamsInformation {
  item: {
    name: string;
    position: string;
    img: string;
    imgWebp: string;
  };
  index: number;
}

const TeamsInformation: FC<ITeamsInformation> = ({ item, index }) => {
  const classes = useStyles();
  const [hover, setHover] = useState<{ [key: number]: boolean }>({});

  const onMouseOver = (index: number) => {
    setHover((currentHover) => ({ ...currentHover, [index]: true }));
  };

  const onMouseOut = (index: number) => {
    setHover((currentHover) => ({ ...currentHover, [index]: false }));
  };

  return (
    <Grid item xs={12} sm={12} md={6}>
      <Card
        className={classes.card}
        onMouseOver={() => onMouseOver(index)}
        onMouseOut={() => onMouseOut(index)}
      >
        <CardContent className={classes.content}>
          <Box className={classes.imgWrapper}>
            <img src={item.imgWebp} alt={item.name} className={classes.img} />
          </Box>

          {!hover[index] && (
            <Box className={classes.teamName}>
              <Typography variant='h3'>{item.name}</Typography>
              <Typography variant='body2'>{item.position}</Typography>
            </Box>
          )}

          {hover[index] && (
            <Box className={cx({ [classes.teamOverLay]: hover[index] })}>
              <Typography variant='h3'>{item.name}</Typography>
              <Typography variant='body2'>{item.position}</Typography>
            </Box>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default TeamsInformation;
