import { FC, useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import * as service from 'src/service';
import { PropertyCard } from 'src/components/CardBox';
import { texts } from 'src/constants';
import { Typography, useMediaQuery } from '@mui/material';
import { useStyles } from './PropertiesStyles';

const Properties: FC = () => {
  const classes = useStyles();
  const isText = useMediaQuery('(max-width: 415px)');
  const [properties, setProperties] = useState<Record<string, any>[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    service.property
      .getAllProperties()
      .then((res) => {
        setProperties(res.data.properties);
        setIsLoading(false);
      })
      .catch(console.error)
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <section className={classes.section}>
      {isText ? (
        <Fade bottom cascade>
          <Typography className={classes.titleMobile} variant='h2'>
            {texts.properties.moreProperties}
          </Typography>
          <Typography className={classes.titleMobileT} variant='h2'>
            {texts.properties.forSale}
          </Typography>
        </Fade>
      ) : (
        <Fade bottom cascade>
          <Typography className={classes.title} variant='h2'>
            {texts.properties.morePropertiesForSale}
          </Typography>
        </Fade>
      )}

      <PropertyCard properties={properties} isLoading={isLoading} />
    </section>
  );
};

export default Properties;
