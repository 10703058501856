import { makeStyles } from '@mui/styles';
import mainLand from 'src/assets/mainland.jpeg';

export const useStyles = makeStyles((theme: any) => ({
  headerContainer: {
    backgroundImage: `url(${mainLand})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    position: 'relative',
    ' &::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0,0,0,0.6)',
    },
  },

  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: ' 0 3rem ',
    color: theme.palette.grey[100],
  },

  headerWrapper2: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '.5rem 3rem ',
    width: '100%',
    color: theme.palette.grey[100],
    backgroundColor: theme.palette.primary.main,
  },
  logo: {
    width: '100px',
    backgroundColor: theme.palette.background.default,
    margin: '1rem 0 0',
    position: 'relative',
    zIndex: 2,
    cursor: 'pointer',
  },

  logo2: {
    width: '100px',
    backgroundColor: theme.palette.background.default,
    cursor: 'pointer',
  },

  listItem: {
    display: 'flex',
    alignItems: 'center',
  },
  link: {
    color: theme.palette.white.main,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.orange.main,
    },
  },

  activeLink: {
    color: theme.palette.primary.main,
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },

  activeLink2: {
    color: theme.palette.orange.main,
    borderBottom: `2px solid ${theme.palette.grey[100]}`,
  },
}));
