import { FC } from 'react';
import { TeamsInformation } from 'src/components/modules/About';
import { Image } from 'src/components';
import Fade from 'react-reveal/Fade';
import { Seo, texts } from 'src/constants';
import group from 'src/assets/group.jpeg';
import group2 from 'src/assets/group.webp';
import { information } from './information';
import { Box, Grid, Typography } from '@mui/material';
import { useStyles } from './AboutStyles';

const About: FC = () => {
  const classes = useStyles();

  return (
    <>
      <Seo title={texts.about.about} />
      <Box className={classes.aboutContainer}></Box>

      <section className={classes.section}>
        <Box className={classes.frontierWrapper}>
          <Fade bottom cascade>
            <Typography className={classes.frontierTitle} variant='h2'>
              {texts.about.aboutUs}
            </Typography>
          </Fade>

          <Fade left>
            <article>
              <Typography variant='body2'>
                {texts.about.frontierCommercial}
              </Typography>
              <br />
              <Typography variant='body2'>
                {texts.about.weAreCommitted}
              </Typography>
              <br />
              <Typography variant='body2'>{texts.about.weProvide}</Typography>
            </article>
          </Fade>
        </Box>

        <Box className={classes.objectivesWrapper}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <Image
                src={group}
                alt='building'
                fallback={group2}
                className={classes.objectivesImage}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Box>
                <Typography variant='h3'>{texts.about.ourMission}</Typography>
                <Typography variant='body2'>
                  {texts.about.toProvideFinancial}
                </Typography>
              </Box>

              <Box>
                <Typography variant='h3'>{texts.about.ourVision}</Typography>
                <Typography variant='body2'>{texts.about.topGlobal}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box className={classes.meetUsWrapper}>
          <Box className={classes.team}>
            <Fade bottom cascade>
              <Typography className={classes.meetTitle} variant='h2'>
                {texts.about.meetOurTeam}
              </Typography>
            </Fade>
          </Box>
          <Grid container spacing={2}>
            {information.map((item, index) => (
              <TeamsInformation key={index} item={item} index={index} />
            ))}
          </Grid>
        </Box>
      </section>
    </>
  );
};

export default About;
