import { routing } from 'src/utils';
import { texts } from 'src/constants';
import RoomIcon from '@mui/icons-material/Room';
import CallIcon from '@mui/icons-material/Call';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

enum footerLinks {
  discover = 'Discover',
  quickLinks = 'Quick Link',
  contactUs = 'Contact Us',
}

export const footerFeatures = {
  discover: footerLinks.discover,
  quickLinks: footerLinks.quickLinks,
  contactUs: footerLinks.contactUs,
};

export const footerTexts = {
  [footerFeatures.discover]: {
    title: texts.footer.discover,
  },

  [footerFeatures.quickLinks]: {
    title: texts.footer.quickLinks,
    menu: [
      {
        name: texts.general.home,
        path: routing.home,
      },
      {
        name: texts.general.about,
        path: routing.about,
      },
      {
        name: texts.general.properties,
        path: routing.properties,
      },
      {
        name: texts.general.blog,
        path: routing.blog,
      },
      {
        name: texts.general.contact,
        path: routing.contact,
      },
    ],
  },

  [footerFeatures.contactUs]: {
    title: texts.footer.contactUs,
    address: [
      {
        icon: RoomIcon,
        text: texts.footer.headOffice,
      },
      {
        icon: RoomIcon,
        text: texts.footer.ikejaBranch,
      },
      {
        icon: RoomIcon,
        text: texts.footer.ojoBranch,
      },
      {
        icon: CallIcon,
        text: texts.footer.number,
      },
      {
        icon: MailOutlineIcon,
        text: texts.footer.email,
      },
    ],
  },
};
