import { routing } from 'src/utils';
import { texts } from 'src/constants';

export const menu = [
  {
    path: routing.home,
    name: texts.general.home,
  },
  {
    path: routing.about,
    name: texts.general.about,
  },
  {
    path: routing.properties,
    name: texts.general.properties,
  },
  {
    path: routing.blog,
    name: texts.general.blog,
  },
  {
    path: routing.contact,
    name: texts.general.contact,
  },
];
