const general = {
  testimonials: 'Testimonials',
  home: 'Home',
  about: 'About',
  properties: 'Properties',
  blog: 'Blog',
  contact: 'Contact',
  successMessage: 'Your message has been sent successfully',
};

const featured = {
  bestSelling: 'Best Selling',
  locations: 'Locations',
  bestSellingLocations: 'Best Selling Locations',
  topSelling: 'Here are a few of our top selling estate locations',
  exploreProperties: 'Explore our Properties',
  sqm: 'sqm',
};

const expectations = {
  perfectChoice: 'Why is Frontier Homes the perfect choice?',
  promptAllocation: 'PROMPT ALLOCATION',
  weAreKnown:
    'We are known for prompt allocation. This is one core value we hold dearly and have been true to. We are one of the fastest when it comes to allocation of lands in the real estate industry in Nigeria.',
  integrity: 'INTEGRITY',
  wiseManOnceSaid:
    'A wise man once said that integrity is the seed of achievement.This principle we hold dearly and never fail to live by. We are big on integrity which is why our existing clients refer us to their family and friends.',
  qualityService: 'QUALITY SERVICE',
  weCareAboutOurClients:
    'We care about our clients, this is why we give them excellent service. Our team members are well trained to render top notch service at all times.',
  customerSatisfaction: 'CUSTOMER SATISFACTION',
  ultimateGoal:
    ' This is our ultimate goal. We strive daily to ensure that our prospects, clients and returning clients are satisfied to the best of our capabilities.',
};

const properties = {
  moreProperties: 'More Properties',
  forSale: 'For Sale',
  morePropertiesForSale: 'More Properties For Sale',
};

const footer = {
  discover: 'Discover',
  quickLinks: 'Quick Link',
  contactUs: 'Contact Us',
  frontierHomes: 'Frontier Homes All rights reserved',
  headOffice:
    'Head Office: 144, Egbeda-Idimu road, Seliat bus-stop, Egbeda Lagos',
  ikejaBranch:
    'Ikeja-Branch: 3, Balogun street Anifowose, by Railway crossing ikeja, lagos',
  ojoBranch:
    'Ojo/Iba-Branch: 40, Igbo Elerin Road, Martin B/Stop, Okokomaiko, ojo Lagos',
  number: '070012FRONTIER',
  email: 'info@frontierhomes.ng',
};

const header = {
  frontierCommercialServicesLimited: 'frontier commercial services limited',
  letsHelpYou: "let's help you create wealth through Real Estate in Nigeria",
  search: 'Search',
  allCities: 'All cities',
  enterAnAddress: 'Enter an address, town, street',
  explore: 'Explore our Properties',
  getStartedToday: 'Get Started Today',
  name: 'name',
  email: 'email',
  phone: 'phone number',
  selectLocation: 'SELECT A LOCATION OF INTEREST',
  selectOption: 'Please select an option',
  howSoon: 'HOW SOON DO YOU INTEND TO PURCHASE',
  getMoreDetails: 'get more details',
  immediately: 'Immediately',
  within3Months: 'Within 3 Months',
  withinAYear: 'Within a year',
  justMakingEnquiry: 'Just making enquiry',
};

const about = {
  aboutUs: 'ABOUT US',
  about: 'About',
  frontierCommercial:
    'Frontier Commercial Services Limited the owners of Frontier Homes is a leading global business with its major focus on real estate and financial investments.',
  weAreCommitted:
    'We are committed and dedicated to helping people create wealth through real estate in Nigeria. We simplify the process of buying and selling landed properties and also ensure that our estates are located in areas that are projected for high yielding returns.',
  weProvide:
    'We only provide and deal in locations that are completely free from Government acquisition and ligitation. We go an extra mile to ensure that we do our due diligence so our clients do not have to worry about anything.',
  toProvideFinancial:
    'To provide financial and empowerment opportunities while also creating business opportunities to solve the problem of unemployment.',
  ourMission: 'our mission',
  topGlobal:
    'To become a top global institution and people empowerment company.',
  ourVision: 'our vision',
  meetOurTeam: 'meet our team',
};
export { featured, expectations, properties, general, footer, header, about };
