import { FC, useState, useEffect } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import MobileHeaderMenu from './MobileHeaderMenu';
import { routing } from 'src/utils';
import { data } from 'src/constants';
import cx from 'classnames';
import Logo from 'src/assets/logo4.png';
import { SearchProperties } from 'src/components';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useStyles } from './HeaderStyles';

const Header: FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery(useTheme().breakpoints.up('md'));
  const [activeLink, setActiveLink] = useState('');

  useEffect(() => {
    setActiveLink(pathname.substring(0));
  }, [pathname]);

  const classes = useStyles();
  return (
    <>
      <Box
        className={cx({
          [classes.headerContainer]: activeLink === routing.home,
        })}
      >
        {isNonMobile && (
          <header
            className={cx({
              [classes.headerWrapper]: activeLink === routing.home,
              [classes.headerWrapper2]: activeLink !== routing.home,
            })}
          >
            <img
              src={Logo}
              alt='logo'
              onClick={() => navigate(routing.home)}
              className={cx(
                { [classes.logo]: activeLink === routing.home },
                {
                  [classes.logo2]: activeLink !== routing.home,
                }
              )}
            />
            <nav>
              <List className={classes.listItem}>
                {data.menu.map((item) => {
                  const isActive =
                    activeLink === item.path || activeLink === item.name;
                  const isHomeLink =
                    activeLink === routing.home &&
                    item.name &&
                    activeLink === item.path;
                  return (
                    <Link
                      to={item.path}
                      key={item.name}
                      className={classes.link}
                      onClick={() => setActiveLink(item.path)}
                    >
                      <ListItem
                        className={cx(
                          {
                            [classes.activeLink2]: !isHomeLink && isActive,
                          },
                          {
                            [classes.activeLink]: isActive,
                          }
                        )}
                      >
                        <ListItemText primary={item.name} />
                      </ListItem>
                    </Link>
                  );
                })}
              </List>
            </nav>
          </header>
        )}

        {activeLink === routing.home && <SearchProperties />}
      </Box>
      {!isNonMobile && <MobileHeaderMenu />}
    </>
  );
};

export default Header;
