import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Image } from 'src/components';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { texts } from 'src/constants';
import { routing } from 'src/utils';
import { ActionButton } from 'src/components/Forms';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import {
  Typography,
  Grid,
  Card,
  CardContent,
  Box,
  useMediaQuery,
} from '@mui/material';
import { useStyles } from './CardBoxStyles';

interface IPropertyCardItem {
  property: Record<string, any>;
}

const ProPertyItem: FC<IPropertyCardItem> = ({ property }) => {
  const classes = useStyles();
  const handle = useFullScreenHandle();
  const isNonMobile = useMediaQuery('(max-width: 900px)');
  return (
    <Grid item>
      <Card>
        <CardContent>
          <Box className={classes.imgWrapper}>
            <Link
              to={`${routing.property}/${property.slug}`}
              className={classes.link}
            >
              <FullScreen handle={handle}>
                <Image
                  src={property.propertyImage[0].url}
                  alt={property.propertyName}
                  fallback={property.propertyImage[0].url}
                  className={
                    handle.active ? classes.screenWrapper : classes.propertyImg
                  }
                />
              </FullScreen>

              <Box className={classes.btnCard}>
                {property.propertyFeatured && (
                  <Box className={classes.btnFeatured}>
                    <ActionButton>{property.propertyFeatured}</ActionButton>
                  </Box>
                )}
                {!isNonMobile && (
                  <Box className={classes.tagsWrapper}>
                    <ActionButton>{property.propertyStatus}</ActionButton>
                  </Box>
                )}
              </Box>
            </Link>

            <Box className={classes.priceWrapper}>
              <Typography variant='body2'>
                ₦{property.propertyPrice.toLocaleString()}
              </Typography>

              {!isNonMobile && (
                <CloseFullscreenIcon
                  onClick={handle.enter}
                  style={{ color: 'white' }}
                />
              )}
            </Box>
          </Box>

          <Box className={classes.cardInfo}>
            <Link
              to={`${routing.property}/${property.slug}`}
              className={classes.link}
            >
              <Typography variant='h4'>
                {property.propertyName.length > 40
                  ? property.propertyName.substring(0, 40) + '...'
                  : property.propertyName}
              </Typography>
            </Link>
            <Typography variant='body2'>
              {property.propertyDescription.length > 40
                ? property.propertyDescription.substring(0, 40) + '...'
                : property.propertyDescription}
            </Typography>
            <Typography variant='body2'>
              {property.propertySize} {texts.featured.sqm}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default ProPertyItem;
