import mrsMaryann from 'src/assets/mr.jpeg';
import mrsMaryann2 from 'src/assets/mr.webp';
import oseni from 'src/assets/os.jpeg';
import oseni2 from 'src/assets/os.webp';
import tolulope from 'src/assets/to.jpeg';
import tolulope2 from 'src/assets/to.webp';
import workers from 'src/assets/workers.jpeg';
import workers2 from 'src/assets/workers.webp';
import team from 'src/assets/team.jpeg';
import team2 from 'src/assets/team.webp';

export const information = [
  {
    name: 'Olalekan  Oseni',
    position: 'Managing Director Frontier Homes',
    img: oseni,
    imgWebp: oseni2,
  },
  {
    name: 'Tolulope Adekunle',
    position: 'Executive Director Frontier Homes',
    img: tolulope,
    imgWebp: tolulope2,
  },
  {
    name: 'Maryann Opara',
    position: 'Head of Sales Frontier Homes',
    img: mrsMaryann,
    imgWebp: mrsMaryann2,
  },
  {
    name: ' Executive Leader',
    position: '',
    img: workers,
    imgWebp: workers2,
  },
  {
    name: 'Members of the Staff',
    position: '',
    img: team,
    imgWebp: team2,
  },
];
